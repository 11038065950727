import 'react-app-polyfill/stable'
import 'Logic/polyfillObjectEntries.js'
import './version.js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App/index.js'
import { ErrorBoundary } from 'Logic/ErrorBoundary.js'

// not ideal but...
let error = window.console.error
window.console.error = (...args) => {
  if (/cannot appear as a descendant of/.test(args[0])) return

  error(...args)
}

let root = createRoot(document.getElementById('root'))

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)
