import View from './view.js'
import React from 'react'

export default function Logic(props) {
  let id = props.item || props.id
  return (
    <View
      // we override viewPath here for analytics
      {...{ ...props, viewPath: `${props.parentViewPath}/Time` }}
      id={id}
      onClick={() => props.onClick(id)}
    />
  )
}
