import useRecaptchaApi from './use-recaptcha-api.js'

export default function useRecaptcha() {
  let recaptcha = useRecaptchaApi({ key: process.env.REACT_APP_RECAPTCHA_KEY })

  async function getToken(action = 'homepage') {
    return new Promise((resolve, reject) => {
      try {
        recaptcha.api.ready(async () => {
          try {
            let token = await recaptcha.api.execute(
              process.env.REACT_APP_RECAPTCHA_KEY,
              {
                action,
              }
            )
            resolve(token)
          } catch (error) {
            reject(error)
          }
        })
      } catch (error) {
        resolve(false)
      }
    })
  }

  return {
    getToken,
    isReady: recaptcha.isReady,
    isLoading: recaptcha.isLoading,
    isError: recaptcha.isError,
  }
}
