import { useSetFlowTo } from 'Simple/Flow.js';
import View from './view.js';
import React, { useEffect } from 'react';
import { useDataHelpers } from 'Data/useData.js';

export default function Logic(props) {
  let dataHelpers = useDataHelpers();
  let setFlow = useSetFlowTo();

  useEffect(() => {
    if (dataHelpers.progress !== 1) {
      setFlow('/App/Form/Progress/Ongoing');
    }
  }, [dataHelpers.progress, setFlow]);

  return <View {...props} />;
}
