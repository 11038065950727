import { validate as isValidEmail } from 'email-validator'
import {
  DENTAL_PLAN,
  QUESTION_SKIPPED,
  RELATIONSHIPS,
  PHONE_TYPES,
  LOCATION_VISIT_TYPE,
} from './common-constants.js'
import phoneRegex from 'phone-regex'

export { isValidEmail }

export let isValidLocation = (value, locations) =>
  locations.some(location => location.id === value)

export let isValidAppointment = value => value.date && value.time

// https://stackoverflow.com/a/2385967/1562732
export let isValidName = value =>
  value &&
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u.test(
    value.trim()
  )

export let isValidPhone = value =>
  phoneRegex({ exact: true }).test(value) || value === QUESTION_SKIPPED

export let isValidPhoneType = value => PHONE_TYPES.includes(value)

export let isValidSSN = value =>
  /^\d{3}-?\d{2}-?\d{4}$/.test(value) || value === QUESTION_SKIPPED

export let isValidTrueFalse = value => [true, false].includes(value)

export let isValidTextInput = value => value && value.length >= 2

export let isValidResponsiblePerson = value =>
  ['patient', 'other-person'].includes(value)

export let isValidRelationship = value => RELATIONSHIPS.indexOf(value) !== -1

export let isValidDentalPlan = value => value in DENTAL_PLAN

export let isValidLocationVisitType = value => value in LOCATION_VISIT_TYPE

export let isValidDate = value =>
  value === ''
    ? false
    : /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/.test(
        value
      )

export let isValidBirthday = value =>
  isValidDate(value) && new Date(value) <= new Date()
