import View from './view.js'
import React from 'react'
import {
  INVALID_FIELD_MESSAGE,
  QUESTION_SKIPPED,
} from 'Data/common-constants.js'

export default function Logic(props) {
  let isReminder = props.text === INVALID_FIELD_MESSAGE
  let isSkipped = props.text === QUESTION_SKIPPED
  return (
    <View
      {...props}
      isRegular={!isReminder && !isSkipped}
      isReminder={isReminder}
      isSkipped={isSkipped}
      onClick={() => props.onClick(props.id)}
    />
  )
}
