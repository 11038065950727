import { useEffect, useState } from 'react';

export function getUserLocation() {
  return new Promise(resolve => {
    if (!navigator.geolocation) resolve(null);

    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      error => {
        // TODO we're not handling the case where a user denies the location
        // so for now we're defaulting to no location
        resolve(null);
      }
    );
  });
}

export function useUserLocation() {
  let [location, setLocation] = useState(null);

  useEffect(() => {
    let cancel = false;
    (async () => {
      let result = await getUserLocation();
      if (cancel) return;

      setLocation(result);
    })();
    return () => (cancel = true);
  }, []);

  return location;
}
