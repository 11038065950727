import { useFlow, useSetFlowTo } from 'Simple/Flow.js'
import { useData, useDataDispatch, useDataHelpers } from 'Data/useData.js'
import { scheduleAppointment } from 'Logic/scheduleAppointment.js'
import { reset } from 'Data/helpers.js'
import React, { useEffect } from 'react'
import View from './view.js'
import useRecaptcha from 'Logic/use-recaptcha.js'

export default function Logic(props) {
  let setFlow = useSetFlowTo()
  let recaptcha = useRecaptcha()
  let data = useData()
  let dataHelpers = useDataHelpers()
  let dispatch = useDataDispatch()

  let flowState = useFlow()
  let isProvideData = flowState.has('/App/Form/Data/ProvideData')

  useEffect(() => {
    if (dataHelpers.progress !== 100 || isProvideData) {
      setFlow('/App/Form/Progress/Ongoing')
    }
  }, [dataHelpers.progress, setFlow, isProvideData])

  return (
    <View
      {...props}
      isDisabled={!recaptcha.isReady}
      onClick={async () => {
        try {
          setFlow('/App/Form/Progress/BookingLoading')
          let captchaToken = await recaptcha.getToken()
          let res = await scheduleAppointment(data, captchaToken)

          dataHelpers.setLocationPhone(res.locationPhone || '')

          if (res.isAppointmentTaken) {
            dataHelpers.setIsAppointmentTaken(true)
            dispatch(reset('appointmentSlot'))
            setFlow('/App/Form/Data/ProvideData/AppointmentSlot')
            setFlow('/App/Form/Progress/Ongoing')
          } else if (res.patientAlreadyExists) {
            setFlow('/App/PatientAlreadyExists')
          } else if (res.errors.length > 0) {
            setFlow('/App/RuntimeError')
          } else {
            dataHelpers.setAppointment({
              patientUuid: res.patientUuid,
              isMedicalFormEnabled: res.isMedicalFormEnabled,
              appointmentId: res.appointmentId,
              medicalFormUrl: res.medicalFormUrl,
            })
            setFlow('/App/Confirmation')
          }
        } catch (error) {
          setFlow('/App/RuntimeError')
        }
      }}
    />
  )
}
