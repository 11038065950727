import View from './view.js';
import React from 'react';

let InputLogic = React.forwardRef((props, ref) => {
  let handleKeyUp = event => {
    if (event.key === 'Enter' && typeof props.onSubmit === 'function') {
      window.dataLayer &&
        window.dataLayer.push({
          event: 'Pageview',
          pagePath: props['data-view-path'],
        });
      props.onSubmit();
    }
  };

  return (
    <View
      {...props}
      onKeyUp={handleKeyUp}
      onChange={event => props.onChange(event.target.value)}
      innerRef={ref}
    />
  );
});

export default InputLogic;
