import format from 'date-fns/format';
import parse from 'date-fns/parse';

let CORE_FORMAT = 'HH:mm';
let APP_FORMAT = 'h:mm a';

export let fromCore = time =>
  format(parse(time, CORE_FORMAT, new Date()), APP_FORMAT);

export let toCore = time =>
  format(parse(time, APP_FORMAT, new Date()), CORE_FORMAT);
