import { RELATIONSHIPS } from 'Data/common-constants.js'
import { set } from 'Data/helpers.js'
import { useData, useDataDispatch } from 'Data/useData.js'
import React from 'react'
import View from './view.js'
import useSetFlowToNextStory from 'Data/useSetFlowToNextStory.js'
import useFocusArea from 'Logic/useFocusArea'

let choices = RELATIONSHIPS.slice(0, -1).map(id => ({ id, text: id }))

export default function Logic(props) {
  let data = useData()
  let dispatch = useDataDispatch()
  // Would it make sense to move strings like DentalPlan into some other place?
  // I guess it's ok to keep them in the components for now.
  let setFlowToNextStory = useSetFlowToNextStory(
    'ResponsiblePersonRelationship'
  )
  let focusArea = useFocusArea()

  return (
    <View
      choices={choices}
      focusArea={focusArea}
      onClick={value => {
        dispatch(set('responsiblePersonRelationship', value))
        setFlowToNextStory()
      }}
      value={data.responsiblePersonRelationship}
    />
  )
}
