import React, { useEffect } from 'react';
import View from './view.js';
import useOnline from 'Logic/useOnline.js';
import { useSetFlowTo } from 'Simple/Flow.js';

export default function Logic(props) {
  let isOnline = useOnline();
  let setFlow = useSetFlowTo();

  useEffect(() => {
    isOnline
      ? setFlow('/App/Form/Connection/Online')
      : setFlow('/App/Form/Connection/Offline');
  }, [isOnline]); //eslint-disable-line
  // setFlow will not change

  return <View {...props} />;
}
