import { getNextInvalidField } from './helpers.js';
import { useDataHelpers } from './useData.js';
import { useFlow, useSetFlowTo } from 'Simple/Flow.js';
import { useRef, useEffect } from 'react';
import toCamelCase from 'to-camel-case';

export default function useSetFlowToNextStory(fieldId) {
  let dataHelpers = useDataHelpers();
  let flow = useFlow();
  let setFlow = useSetFlowTo();
  let run = useRef(false);

  useEffect(() => {
    if (!run.current) return;
    if (!flow.has(`/App/Form/Data/ProvideData/${fieldId}`)) return;

    let next = getNextInvalidField(
      dataHelpers.valid,
      dataHelpers.applicableData,
      toCamelCase(fieldId)
    );

    setFlow(
      next ? `/App/Form/Data/ProvideData/${next}` : '/App/Form/Data/ReviewData'
    );
  }, [dataHelpers.valid, dataHelpers.applicableData, fieldId]); // eslint-disable-line
  // setFlow doesn't change

  return () => {
    run.current = true;
  };
}
