import { useDataHelpers } from 'Data/useData.js'
import { useSetFlowTo } from 'Simple/Flow.js'
import View from './view.js'
import React, { useState, useEffect } from 'react'
import addMonths from 'date-fns/addMonths'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import subMonths from 'date-fns/subMonths'
import useDebounce from 'Logic/use-debounce.js'
import useField from 'Data/useField.js'
import useFocusArea from 'Logic/useFocusArea.js'

let noSlotsAvailable = timeSlots =>
  !Boolean(Object.keys(timeSlots).find(day => timeSlots[day].length > 0))

let getNotification = (dataHelpers, timeSlots) => {
  if (dataHelpers.isAppointmentTaken) {
    return 'AppointmentTaken'
  }
  return noSlotsAvailable(timeSlots) ? 'NoSlotsAvailable' : 'None'
}

export default function Logic(props) {
  let dataHelpers = useDataHelpers()
  let field = useField('AppointmentSlot')
  let [month, setMonth] = useState(() =>
    field.value.date
      ? parse(field.value.date, 'MM/dd/yyyy', new Date())
      : new Date()
  )
  let monthStable = useDebounce(month)
  let setFlow = useSetFlowTo()
  let focusArea = useFocusArea()

  useEffect(() => {
    setFlow(`/App/Form/Data/ProvideData/AppointmentSlot/Notifications/Loading`)
  }, []) // eslint-disable-line

  // react to the calendar data changing
  useEffect(() => {
    let cancel = false

    ;(async () => {
      let timeSlots = await dataHelpers.getTimeSlots(monthStable)
      if (cancel) return
      setFlow(
        `/App/Form/Data/ProvideData/AppointmentSlot/Notifications/${getNotification(
          dataHelpers,
          timeSlots
        )}`
      )
    })()

    return () => (cancel = true)
    /* eslint-disable-next-line */
  }, [dataHelpers.isAppointmentTaken, monthStable])

  return (
    <View
      {...field}
      parentViewPath={props.viewPath}
      month={month}
      monthSlots={dataHelpers.monthSlots}
      focusArea={focusArea}
      onChange={(date, time) => {
        field.onChange({
          date: format(date, 'MM/dd/yyyy'),
          time,
        })
      }}
      onClickAppointmentTaken={() => dataHelpers.setIsAppointmentTaken(false)}
      onClickNextMonth={() => changeMonthNext(month)}
      onClickPrevMonth={() => changeMonthNext(month, false)}
    />
  )

  function changeMonthNext(month, next = true) {
    setFlow(`/App/Form/Data/ProvideData/AppointmentSlot/Notifications/Loading`)
    setMonth(next ? addMonths(month, 1) : subMonths(month, 1))
  }
}
