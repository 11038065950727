import * as fromStatus from 'Logic/status.js'
import { useConfiguration } from './Configuration.js'
import React, { useContext, useEffect, useState } from 'react'
import get from 'lodash/get'
import qs from 'querystringify'

let USE_LOCATION_NAME = !!qs.parse(window.location.search).locationNames

let Context = React.createContext({})

function parse(rawLocations) {
  return rawLocations
    .filter(
      location =>
        get(location, 'simplifiedAddressDTO.streetAddress1') &&
        get(location, 'coordinates.lat') &&
        get(location, 'coordinates.lng')
    )
    .map(location => {
      let { city, stateDTO, streetAddress1, zip } = get(
        location,
        'simplifiedAddressDTO',
        { id: '', city: '', streetAddress: '', zip: '' }
      )
      return {
        zip,
        id: location.id,
        medicalPlans: location.medicalPlans,
        text: `${city}, ${get(stateDTO, 'id', '')}`,
        locationName: USE_LOCATION_NAME && location.name,
        subtext: streetAddress1,
        ...location.coordinates,
      }
    })
    .sort((a, b) => a.zip - b.zip)
}

function filterLocationsByDentalPlan(locations, dentalPlan) {
  return locations.filter(({ medicalPlans }) => {
    return (
      medicalPlans &&
      medicalPlans
        .map(medicalPlan => medicalPlan.replace('_VIRTUAL', ''))
        .includes(dentalPlan)
    )
  })
}

export function Locations(props) {
  let configuration = useConfiguration()
  let [locations, setLocations] = useState({
    data: [],
    status: fromStatus.LOADING,
  })

  useEffect(() => {
    if (fromStatus.isLoading(configuration.status)) return

    if (fromStatus.isReady(configuration.status)) {
      setLocations({
        status: fromStatus.READY,
        data: parse(get(configuration, 'data.__rawLocations', [])),
      })
    } else {
      setLocations({
        status: fromStatus.ERROR,
        data: [],
      })
    }
  }, [configuration.status]) // eslint-disable-line
  // configuration.data.__rawLocations don't matter

  return <Context.Provider value={locations}>{props.children}</Context.Provider>
}

export let useLocations = dentalPlan => {
  let locations = useContext(Context)
  return filterLocationsByDentalPlan(locations.data, dentalPlan)
}
