import * as fromStatus from 'Logic/status.js'
import getDivisionId from 'Data/useDivisionId.js'
import React, { useContext, useEffect, useState } from 'react'

let Context = React.createContext({})

export function Configuration(props) {
  let [configuration, setConfiguration] = useState(() => setDefaultConfig())

  useEffect(() => {
    if (!configuration.data.divisionId) {
      setConfiguration({
        ...configuration,
        errorView: 'DivisionIdError',
        status: fromStatus.ERROR,
      })
      return
    }

    let cancel = false
    ;(async () => {
      let res = await executeQuery({
        division_access_key: parseInt(configuration.data.divisionId, 10),
      })
      if (cancel) return

      let data = res?.data?.appointments_legacy_structure_get_widget_data?.data
      let errors = res?.errors

      if (
        !data ||
        Array.isArray(errors) ||
        data?.locationDTOList === null ||
        areNoAppointmentTemplates(data)
      ) {
        setConfiguration({
          ...configuration,
          errorView: 'RuntimeError',
          status: fromStatus.ERROR,
        })
      } else {
        setConfiguration({
          status: fromStatus.READY,
          data: {
            ...configuration.data,
            medicaidEnabled: data.medicaidEnabled,
            welcomeEnabled: data.welcomeEnabled,
            __rawLocations: data?.locationDTOList,
          },
        })
      }
    })()

    return () => (cancel = true)
  }, []) // eslint-disable-line
  // we don't care about configuration.divisionId changing

  return (
    <Context.Provider value={configuration}>{props.children}</Context.Provider>
  )
}

async function executeQuery(variables) {
  return fetch(process.env.REACT_APP_API, {
    method: 'POST',
    body: JSON.stringify({
      query: QUERY,
      variables,
    }),
    options: {
      headers: {
        'x-hasura-role': 'public',
      },
    },
  }).then(res => res.json())
}

function setDefaultConfig() {
  let divisionId = getDivisionId()

  let initialConfiguration = {
    data: {
      divisionId,
      medicaidEnabled: false,
      appointmentsAvailable: false,
    },
    status: fromStatus.LOADING,
  }

  return initialConfiguration
}

function areNoAppointmentTemplates(data) {
  return data?.locationDTOList.every(l =>
    data.acceptsMedicaid
      ? l.medicalPlans.length === 0
      : l.medicalPlans.filter(
          plan => plan.toLowerCase().replace('_virtual', '') !== 'medicaid'
        ).length === 0
  )
}

let QUERY = `
query get_widget_data(
  $division_access_key: Int!
) {
  appointments_legacy_structure_get_widget_data(
    args: { _division_access_key: $division_access_key }
  ) {
    data
  }
}
`

export let useConfiguration = () => useContext(Context)
