import { useSetFlowTo, useFlow } from 'Simple/Flow.js'
import { useMemo } from 'react'
import { useData, useDataHelpers } from 'Data/useData.js'
import { useLocations } from 'Data/Locations.js'
// import intersection from 'Logic/intersection.js';
import toCamelCase from 'to-camel-case'
import toPascalCase from 'to-pascal-case'

let usePreviousQuestion = () => {
  let data = useData()
  let locations = useLocations(data.dentalPlan)
  let dataHelpers = useDataHelpers()
  let flowState = useFlow()
  let setFlow = useSetFlowTo()

  return useMemo(() => {
    // let flowMapForStory = flowDefinition['/App/Form/Data/ProvideData'];

    // if (process.env.REACT_APP_ENV === 'development') {
    //   if (!flowMapForStory) {
    //     return {
    //       id: null,
    //       onClick: () => {},
    //       value: null,
    //     };
    //   }
    // }

    // let flowActiveStory = [
    //   ...intersection(
    //     [...flowMapForStory.views].map(
    //       id => `/App/Form/Data/ProvideData/${id}`
    //     ),
    //     flowState
    //   ),
    // ][0];
    let flowActiveStory = flowState.flow['/App/Form/Data/ProvideData']
    if (process.env.REACT_APP_ENV === 'development') {
      if (!flowActiveStory) {
        return {
          id: null,
          onClick: () => {},
          value: null,
        }
      }
    }

    let question = toCamelCase(flowActiveStory)

    let index = dataHelpers.applicableData.indexOf(question)
    let id = null
    let value = null

    let flowStateAsData = toCamelCase(flowActiveStory)
    let flowStateAsDataIndex =
      dataHelpers.applicableData.indexOf(flowStateAsData)
    let prevFlowStateAsData =
      dataHelpers.applicableData[flowStateAsDataIndex - 1]
    // TODO review this, we shouldn't need it
    if (!prevFlowStateAsData) {
      return {
        id: null,
        onClick() {},
        value: null,
      }
    }
    let prevFlowState = toPascalCase(prevFlowStateAsData)

    // with the initial data being filled in, we have a case in which the
    // previous question in the applicableData list isn't filled yet, so we need
    // to look for the next possible field
    let locationValue = data.location
      ? locations.find(locationObject => locationObject.id === data.location)
          .text
      : null

    do {
      index--
      id = dataHelpers.applicableData[index]
      value =
        prevFlowState === 'Location'
          ? `${locationValue}${
              data.locationVisitType === 'virtual' ? ' • Virtual Visit' : ''
            }`
          : dataHelpers.repeaters[`${id}Repeater`]
    } while (!value && index > 0)

    id = toPascalCase(id)

    return {
      id,
      onClick: () => setFlow(`/App/Form/Data/ProvideData/${id}`),
      value,
    }
  }, [
    flowState,
    dataHelpers.applicableData,
    dataHelpers.repeaters,
    data.location,
    data.locationVisitType,
    locations,
    setFlow,
  ])
}

export default usePreviousQuestion
