import View from './view.js';
import React from 'react';
import useHover from '../use-hover.js';

export default function Logic(props) {
  let { isHovered, onMouseEnter, onMouseLeave } = useHover();

  return (
    <View
      {...props}
      isHoveredManual={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
}
