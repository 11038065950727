import View from './view.js';
import React from 'react';

export default function Logic(props) {
  return (
    <View
      {...props}
      onClick={() => {
        if (typeof props.onClick === 'function') {
          props.onClick(props.id);
        }
      }}
    />
  );
}
