import { set } from 'Data/helpers.js'
import { useData, useDataDispatch } from 'Data/useData.js'
import React from 'react'
import View from './view.js'
import { toCapitalCase } from 'Logic/toCapitalCase.js'
import useSetFlowToNextStory from 'Data/useSetFlowToNextStory.js'
import useFocusArea from 'Logic/useFocusArea'

export default function Logic(props) {
  let data = useData()
  let dispatch = useDataDispatch()
  let setFlowToNextStory = useSetFlowToNextStory('ResponsiblePerson')
  let focusArea = useFocusArea()

  let patientName = `${toCapitalCase(data.patientFirstName)} ${toCapitalCase(
    data.patientLastName
  )}`

  let responsiblePersonQuestion = `Who is financially and legally responsible for the treatment of ${patientName}?`

  return (
    <View
      onClick={value => {
        dispatch(set('responsiblePerson', value))
        setFlowToNextStory()
      }}
      focusArea={focusArea}
      patientName={patientName}
      responsiblePersonQuestion={responsiblePersonQuestion}
      value={data.responsiblePerson}
    />
  )
}
