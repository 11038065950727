import * as fromStatus from 'Logic/status.js'
import { PRIVATE_INSURANCE } from 'Data/common-constants.js'
import { set } from 'Data/helpers.js'
import { useDataHelpers, useDataDispatch } from 'Data/useData.js'
import { useSetFlowTo } from 'Simple/Flow.js'
import { useConfiguration } from 'Data/Configuration.js'
import View from './view.js'
import React, { useEffect } from 'react'

export default function Logic(props) {
  let dataHelpers = useDataHelpers()
  let dispatch = useDataDispatch()
  let setFlow = useSetFlowTo(props.viewPath)
  let configuration = useConfiguration()

  useEffect(() => {
    if (fromStatus.isLoading(configuration.status)) return

    let story = '/App/WelcomeScreen'

    if (fromStatus.isError(configuration.status)) {
      story = `/App/${configuration.errorView || 'RuntimeError'}`
    } else {
      if (!configuration.data.medicaidEnabled) {
        dataHelpers.exclude('dentalPlan')
        dispatch(set('dentalPlan', PRIVATE_INSURANCE))
      }

      if (!configuration.data.welcomeEnabled) {
        story = `/App/Form/Data/ProvideData/${
          configuration.data.medicaidEnabled ? 'DentalPlan' : 'Location'
        }`
      }
    }

    if (process.env.REACT_APP_ENV === 'development') {
      setTimeout(() => {
        setFlow(story)
      }, 2000)
    } else {
      setFlow(story)
    }
  }, [configuration.status]) // eslint-disable-line
  // configuration.data changes with configuration.status

  return <View {...props} />
}
