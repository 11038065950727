import { DATA, set } from './helpers.js';
import { useEffect, useMemo, useState } from 'react';
import { useData, useDataDispatch } from './useData.js';
import toCamelCase from 'to-camel-case';

export default function useSecondaryField(fieldId) {
  let data = useData();
  let dispatch = useDataDispatch();
  let camelCaseField = useMemo(() => toCamelCase(fieldId), [fieldId]);

  let field = DATA[camelCaseField];

  let [value, onChange] = useState(data[camelCaseField]);
  let [isValid, setIsValid] = useState(() => field.isValid(value));

  useEffect(() => {
    setIsValid(field.isValid(value));
  }, [field, value]);

  function onSubmit() {
    if (!isValid) return;

    dispatch(set(field.id, value));
  }

  return { value, onChange, onSubmit };
}
