import { useEffect } from 'react'

export default function Timeout({ onInit, onTimeout, delay = 100 }) {
  useEffect(() => {
    if (typeof onTimeout !== 'function') return

    if (typeof onInit === 'function') {
      onInit()
    }

    let cancel = false

    let id = setTimeout(() => {
      if (cancel) return

      onTimeout()
    }, delay)

    return () => {
      cancel = true
      clearTimeout(id)
    }
  }, [delay]) //eslint-disable-line
  // ignore onInit and onTimeout

  return null
}
