// @view
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

export default function GoogleTagManager(props) {
  if (process.env.REACT_APP_ENV === 'development') {
    if (!process.env.REACT_APP_GTM_ID) {
      console.error(`You're missing process.env.REACT_APP_GTM_ID`)
    }
    if (!process.env.REACT_APP_GTM_ID_AUTH) {
      console.error(`You're missing process.env.REACT_APP_GTM_ID_AUTH`)
    }
    if (!process.env.REACT_APP_GTM_ID) {
      console.error(`You're missing process.env.REACT_APP_GTM_ID_PREVIEW`)
    }
  }

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      auth: process.env.REACT_APP_GTM_ID_AUTH,
      preview: process.env.REACT_APP_GTM_ID_PREVIEW,
      dataLayer: {
        ...props.data,
        version: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_ENV}`,
        env: `${process.env.REACT_APP_ENV}`,
      },
    })
  }, [props.data])
  return null
}
