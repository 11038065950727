import { useData, useDataHelpers } from 'Data/useData.js'
import View from './view.js'
import React from 'react'
import { captureError } from 'Logic/ErrorBoundary'

export default function Logic(props) {
  let data = useData()
  let dataHelpers = useDataHelpers()

  let reset = e => {
    e.preventDefault()
    window.location.reload()
  }

  let medicalFormUrl = null
  let medicalFormEnabled = dataHelpers.appointment.isMedicalFormEnabled
  if (medicalFormEnabled) {
    medicalFormUrl = dataHelpers.appointment.medicalFormUrl

    if (!medicalFormUrl) {
      // Send message to Sentry
      captureError(
        new Error(
          `Appt Widget: No medical form v2 url for ${dataHelpers.appointment.patientUuid}`
        ),
        {
          patient_id: dataHelpers.appointment.patientUuid,
          appointment: dataHelpers.appointment,
        }
      )
      medicalFormEnabled = false
    }
  }

  return (
    <View
      {...props}
      goTo={medicalFormUrl}
      patientUuid={dataHelpers.appointment.patientUuid}
      medicalFormEnabled={medicalFormEnabled}
      title={`${data.patientFirstName} your appointment was scheduled successfully!`}
      subtitle={`A confirmation email was sent to ${data.patientEmail}`}
      reset={reset}
    />
  )
}
