import { useEffect, useRef } from 'react'

export default function useFocusArea() {
  let focusArea = useRef()

  useEffect(() => {
    if (focusArea.current.contains(document.activeElement)) return
    focusArea.current.focus()
  }, [])
  return focusArea
}
