import View from './view.js';
import React from 'react';
import { useDataHelpers } from 'Data/useData.js';

export default function Logic(props) {
  let dataHelpers = useDataHelpers();

  return (
    <View
      {...props}
      {...dataHelpers.repeaters}
      dataValid={dataHelpers.valid.dataValid}
    />
  );
}
