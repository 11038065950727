import View from './view.js'
import React, { useState, useRef } from 'react'
import useOnClickOutside from 'Logic/outsideClickHook'
import format from 'date-fns/format'

export default function Logic(props) {
  let [isShowingTimePicker, setIsShowingTimePicker] = useState(false)
  let isSelected = props.value.date === format(props.day, 'MM/dd/yyyy')

  let dayRef = useRef()
  useOnClickOutside(
    dayRef,
    () => isShowingTimePicker && setIsShowingTimePicker(false)
  )

  return (
    <View
      // we override viewPath here for analytics
      {...{ ...props, viewPath: `${props.parentViewPath}/Day` }}
      day={format(props.day, 'd')}
      dayRef={dayRef}
      time={props.value.time}
      isShowingTimePicker={isShowingTimePicker}
      onClick={() => setIsShowingTimePicker(v => !v)}
      onClickTime={time => {
        props.setDateAndTime(time)
        setIsShowingTimePicker(false)
      }}
      isSelected={isSelected}
      parentViewPath={props.parentViewPath}
    />
  )
}
