import React, {
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { getTimeSlot } from 'Logic/getTimeSlot'
import { INITIAL_DATA, SET } from './common-constants.js'
import {
  getRepeaters,
  getProgress,
  getNextInvalidField,
  getApplicableData,
  DATA,
  DATA_KEYS,
} from './helpers.js'
import { useConfiguration } from './Configuration.js'
import { useLocations } from './Locations.js'
import validate from './validate.js'

let DataContext = React.createContext({})
let DispatchContext = React.createContext(() => {})
let HelpersContext = React.createContext({})

function reducer(state, action) {
  switch (action.type) {
    case INITIAL_DATA:
      return action.data

    case SET:
      return {
        ...state,
        [action.key]: action.value,
      }

    default:
      throw new Error(`Action not implemented ${JSON.stringify(action)}`)
  }
}

let INITIAL_REDUCER_STATE = DATA_KEYS.reduce((state, key) => {
  state[key] = DATA[key].defaultValue
  return state
}, {})

export let Data = props => {
  let [exclude, setExclude] = useState([])

  let [data, dispatch] = useReducer(reducer, INITIAL_REDUCER_STATE)

  let locations = useLocations(data.dentalPlan)

  // TODO move getTimeSlots into its own context
  let configuration = useConfiguration()
  let [monthSlots, setMonthSlots] = useState({})
  let getTimeSlots = useCallback(
    async date => {
      if (configuration.status === 'loading') return

      let dentalPlanType = `${data.dentalPlan}${
        data.locationVisitType === 'virtual' ? '_VIRTUAL' : ''
      }`

      let slots = await getTimeSlot(data.location, dentalPlanType, date)

      setMonthSlots(slots)

      return slots
    },
    [
      data.location,
      data.locationVisitType,
      data.dentalPlan,
      setMonthSlots,
      configuration.status,
    ]
  )

  // TODO move out
  let [appointment, setAppointment] = useState(null)
  let [isAppointmentTaken, setIsAppointmentTaken] = useState(false)
  let [locationPhone, setLocationPhone] = useState('')

  let applicableData = useMemo(
    () => getApplicableData(data, exclude),
    [data, exclude]
  )
  let valid = useMemo(
    () => validate(data, applicableData, locations, monthSlots),
    [data, applicableData, locations, monthSlots]
  )
  let nextInvalidField = useMemo(
    () => getNextInvalidField(valid, applicableData),
    [valid, applicableData]
  )
  let repeaters = useMemo(
    () => getRepeaters(data, nextInvalidField, exclude),
    [data, nextInvalidField, exclude]
  )
  let progress = useMemo(
    () => getProgress(valid, applicableData),
    [valid, applicableData]
  )

  let contextValue = useMemo(
    () => ({
      applicableData,
      appointment,
      setAppointment,
      monthSlots,
      getTimeSlots,
      nextInvalidField,
      progress,
      repeaters,
      valid,
      isAppointmentTaken,
      setIsAppointmentTaken,
      locationPhone,
      setLocationPhone,
      exclude: field => {
        setExclude(exclude => {
          if (exclude.includes(field)) {
            return exclude
          } else {
            return [...exclude, field]
          }
        })
      },
      isFieldExcluded: field => exclude.includes(field),
    }),
    [
      applicableData,
      appointment,
      exclude,
      getTimeSlots,
      isAppointmentTaken,
      monthSlots,
      nextInvalidField,
      progress,
      repeaters,
      setAppointment,
      setIsAppointmentTaken,
      valid,
      locationPhone,
      setLocationPhone,
    ]
  )

  return (
    <DataContext.Provider value={data}>
      <DispatchContext.Provider value={dispatch}>
        <HelpersContext.Provider value={contextValue}>
          {props.children}
        </HelpersContext.Provider>
      </DispatchContext.Provider>
    </DataContext.Provider>
  )
}

export let useData = () => useContext(DataContext)
export let useDataDispatch = () => useContext(DispatchContext)
export let useDataHelpers = () => useContext(HelpersContext)

export let isPrimaryField = field => !DATA[field].isSecondary
