import * as formatTime from './formatTime.js';
import format from 'date-fns/format';

async function executeQuery(variables) {
  return fetch(process.env.REACT_APP_API, {
    method: 'POST',
    body: JSON.stringify({
      query: QUERY,
      variables,
    }),
    options: {
      headers: {
        'x-hasura-role': 'public',
      },
    },
  }).then((res) => res.json());
}

let encodeDate = (year, month) =>
  format(new Date(year, month), `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`);

let getFirstAndLastDay = (date) => {
  let month = date.getMonth();
  let year = date.getFullYear();

  return {
    firstDay: encodeDate(year, month),
    lastDay: encodeDate(year, month + 1),
  };
};

export let getTimeSlot = async (locationId, medicalPlan, date) => {
  let { firstDay, lastDay } = getFirstAndLastDay(date);

  let res = await executeQuery({
    from: firstDay,
    until: lastDay,
    location_id: parseInt(locationId, 10),
    medical_plan: medicalPlan,
  });

  let error = !res || res.error;

  let data = res.data?.appointment_widget_request_schedule_slots;

  // TODO handle this on the app, the app should go in a runtime error if the
  // backend failed here
  if (error || !data) return {};

  return data.reduce((accumulator, currentValue) => {
    accumulator[currentValue.date] = currentValue.slotsAvailability.map(
      formatTime.fromCore
    );
    return accumulator;
  }, {});
};

let QUERY = `
query appointment_widget_request_schedule_slots(
  $from: String!
  $until: String!
  $location_id: numeric!
  $medical_plan: MedicalPlan!
) {
  appointment_widget_request_schedule_slots(
    from: $from
    until: $until
    location_id: $location_id
    medical_plan: $medical_plan 
  ) {
    date
    slotsAvailability
  }
}
`;
