import * as formatTime from './formatTime.js'
import parse from 'date-fns/parse'
import format from 'date-fns/format'

export let scheduleAppointment = async (userData, captchaToken) => {
  let selectedDay = reformatDate(userData.appointmentSlot.date)
  let selectedTime = formatTime.toCore(userData.appointmentSlot.time)

  let patient = {
    contact_methods_info: {
      email: userData.patientEmail,
      phone: {
        phone_number: cleanupPhone(userData.patientPhone),
        type: userData.patientPhoneType,
      },
      use_this_email: true,
      use_this_phone_number: userData.patientPhoneNotifications,
    },
    patient_basic_info: {
      first_name: userData.patientFirstName,
      last_name: userData.patientLastName,
      date_of_birth: reformatDate(userData.patientBirthday),
    },
  }

  if (userData.responsiblePerson === 'other-person') {
    patient.responsible_person = {
      contact_methods_info: {
        email: userData.responsiblePersonEmail || userData.patientEmail,
        phone: {
          phone_number: cleanupPhone(
            userData.responsiblePersonPhone || userData.patientPhone
          ),
          type: userData.responsiblePersonPhoneType,
        },
        use_this_email: true,
        use_this_phone_number: userData.responsiblePersonPhoneNotifications,
      },
      first_name: userData.responsiblePersonFirstName,
      last_name: userData.responsiblePersonLastName,
      relationship: userData.responsiblePersonRelationship,
    }
  }

  let scheduling = {
    selected_day: selectedDay,
    selected_time: selectedTime,
    medical_plan: `${userData.dentalPlan}${
      userData.locationVisitType === 'virtual' ? '_VIRTUAL' : ''
    }`,
  }

  let task = {
    due_date: selectedDay,
    due_time: selectedTime,
  }

  let errors = []

  let res

  try {
    res = await executeMutation({
      patient,
      scheduling,
      task,
      location_id: parseInt(userData.location, 10),
      captcha_token: captchaToken,
    })
  } catch (err) {
    errors.push(err.message)
  }

  if (res.errors) {
    errors.push.apply(errors, res.errors)
  }

  if (res.data?.appointment_widget_new_patient?.errors) {
    errors.push.apply(errors, res.data.appointment_widget_new_patient.errors)
  }

  return {
    isAppointmentTaken: res.data?.appointment_widget_new_patient
      ?.is_appointment_taken
      ? true
      : false,
    patientAlreadyExists: res.data?.appointment_widget_new_patient
      ?.is_existing_patient
      ? true
      : false,
    errors,
    patientUuid: res.data?.appointment_widget_new_patient?.patient_uuid,
    isMedicalFormEnabled: res.data?.appointment_widget_new_patient
      ?.medical_form_enabled
      ? true
      : false,
    medicalFormUrl: res.data?.appointment_widget_new_patient?.medical_form_url,
    appointmentId: res.data?.appointment_widget_new_patient?.appointment_id,
    locationPhone: res.data?.appointment_widget_new_patient?.phone,
  }
}

let reformatDate = date =>
  format(parse(date, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd')

// TODO: we should update this if we expand to other countries
let cleanupPhone = phone => `+1${phone.replace(/\D+/g, '')}`

async function executeMutation(variables) {
  return fetch(process.env.REACT_APP_API, {
    method: 'POST',
    body: JSON.stringify({
      query: MUTATION,
      variables,
    }),
    options: {
      headers: {
        'x-hasura-role': 'public',
      },
    },
  }).then(res => res.json())
}

let MUTATION = `
mutation appointment_widget_new_patient($patient: Patient!, $scheduling: Scheduling!, $task: Task!, $location_id: numeric!, $captcha_token: String!){
  appointment_widget_new_patient(
    patient: $patient
    scheduling: $scheduling
    task: $task
    location_id: $location_id
    captcha_token: $captcha_token
  ) {
    medical_form_enabled
    medical_form_url
    patient_uuid
    appointment_id
    errors
    phone
    is_appointment_taken
    is_existing_patient
  }
}
`
