import View from './view.js';
import React from 'react';
import { useDataHelpers } from 'Data/useData.js';

export default function Logic(props) {
  let dataHelpers = useDataHelpers();

  return (
    <View {...props} text={dataHelpers.repeaters.appointmentSlotRepeater} />
  );
}
