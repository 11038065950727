// @view
import './Calendar.css'
import CalendarHeader from './CalendarHeader/view.js'
import CalendarNav from './CalendarNav/logic.js'
import Day from './Day/logic.js'
import DayPicker from 'react-day-picker'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import React, { useEffect, useState } from 'react'

let SUNDAY = 0
let SATURDAY = 6

let getVisibleDays = monthSlots => {
  let weekDays = {
    0: false,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: false,
  }
  Object.keys(monthSlots).forEach(day => {
    let dayParsed = parse(day, 'yyyy-MM-dd', new Date()).getDay()

    if (dayParsed === SUNDAY && monthSlots[day].length > 0) {
      weekDays[SUNDAY] = true
    } else if (dayParsed === SATURDAY && monthSlots[day].length > 0) {
      weekDays[SATURDAY] = true
    }
  })

  return weekDays
}

let Calendar = ({
  onChange,
  value,
  monthSlots,
  month,
  onClickPrevMonth,
  onClickNextMonth,
  parentViewPath,
}) => {
  let [visibleDays, setVisibleDays] = useState({})

  useEffect(() => {
    setVisibleDays(getVisibleDays(monthSlots))
  }, [monthSlots])

  return (
    <>
      <CalendarNav
        onClickPrevMonth={onClickPrevMonth}
        onClickNextMonth={onClickNextMonth}
        month={month}
      />
      <DayPicker
        captionElement={() => null}
        month={month}
        disabledDays={[
          {
            before: new Date(),
          },
        ]}
        modifiers={{
          sundays: { daysOfWeek: [0] },
          saturdays: { daysOfWeek: [6] },
        }}
        modifiersStyles={{
          sundays: {
            display: visibleDays[SUNDAY] ? 'flex' : 'none',
          },
          saturdays: {
            display: visibleDays[SATURDAY] ? 'flex' : 'none',
          },
        }}
        navbarElement={() => null}
        renderDay={(day, modifiers) => {
          let slotsAvailability = monthSlots[format(day, 'yyyy-MM-dd')] || []

          return (
            <Day
              day={day}
              isDisabled={modifiers.disabled || slotsAvailability.length === 0}
              setDateAndTime={time => onChange(day, time)}
              value={value}
              slotsAvailability={slotsAvailability}
              parentViewPath={parentViewPath}
            />
          )
        }}
        weekdayElement={props => {
          return visibleDays[props.weekday] ? (
            <CalendarHeader
              date={props.localeUtils.formatWeekdayShort(props.weekday)}
            />
          ) : null
        }}
      />
    </>
  )
}

export default Calendar
