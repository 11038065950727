import { useDataHelpers } from 'Data/useData.js';
import React from 'react';
import View from './view.js';

export default function Logic(props) {
  let dataHelpers = useDataHelpers();
  let locationPhone = dataHelpers.locationPhone;
  return (
    <View
      text={`Our systems detect there is already an appointment that matches the info you have used to schedule. Please call the office ${
        locationPhone ? `at ${locationPhone}` : ''
      } to reschedule or cancel this appointment.`}
    />
  );
}
