import { DATA } from './helpers.js'
import { GROUP } from './common-constants.js'

export default function validate(
  dataState,
  applicableData,
  locations,
  monthSlots
) {
  let valid = { dataValid: true }

  applicableData.forEach(key => {
    let keyValid = `${key}Valid`

    let field = DATA[key]

    if (field.type === GROUP) {
      valid[keyValid] = true

      field.fields.forEach(innerKey => {
        let innerField = DATA[innerKey]

        if (!innerField.isValid(dataState[innerKey])) {
          valid[keyValid] = false
        }
      })
    } else if (key === 'location') {
      valid[keyValid] =
        dataState[key] && !!field.isValid(dataState[key], locations)
    } else if (key === 'appointmentSlot') {
      valid[keyValid] = !!field.isValid(dataState[key], monthSlots)
    } else {
      valid[keyValid] = !!field.isValid(dataState[key])
    }

    // if one value is invalid, then the whole data is invalid
    // this is an oversimplification for now
    if (!valid[keyValid]) {
      valid.dataValid = false
    }
  })

  return valid
}
