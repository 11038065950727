import View from './view.js'
import React from 'react'

export default function Logic(props) {
  let id = props.item ? props.item.id : props.id
  let medicalPlans = props.item ? props.item.medicalPlans : props.medicalPlans
  let distance = props.item ? props.item.distance : props.distance
  let locationVisitType = props.item
    ? props.item.locationVisitType
    : props.locationVisitType

  return (
    <View
      {...props}
      {...props.item}
      parentViewPath={`${
        props.viewPath.match(/^[^(]+/gm)?.[0] || props.viewPath
      }/${props.item.id}/${props.item.text}`}
      innerRef={el => props.innerRef(id, el)}
      onMouseEnter={() => props.onMouseEnter(id)}
      onClick={() => props.onClick(id, locationVisitType)}
      isHighlighted={id && id === props.highlightedLocation}
      isSelected={props.isSelected || (id && id === props.selected)}
      hasDistance={distance}
      hasVirtual={medicalPlans?.includes(`${props.dentalPlan}_VIRTUAL`)}
      hasInPerson={medicalPlans?.includes(props.dentalPlan)}
    />
  )
}
