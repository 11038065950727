import React from 'react'
import View from './view.js'
import { set, reset } from 'Data/helpers.js'
import { useData, useDataDispatch } from 'Data/useData.js'
import { useSetFlowTo } from 'Simple/Flow.js'
import useSetFlowToNextStory from 'Data/useSetFlowToNextStory.js'
import useFocusArea from 'Logic/useFocusArea.js'

export default function Logic(props) {
  let data = useData()
  let dispatch = useDataDispatch()
  let setFlow = useSetFlowTo()
  // Would it make sense to move strings like DentalPlan into some other place?
  // I guess it's ok to keep them in the components for now.
  let setFlowToNextStory = useSetFlowToNextStory('DentalPlan')
  let focusArea = useFocusArea()

  return (
    <View
      {...props}
      focusArea={focusArea}
      onClick={value => {
        if (value === data.dentalPlan) {
          setFlowToNextStory()
        } else {
          // the dental plan choice influences the location and the appointment
          // slot, so we need to reset those when the value changes
          dispatch(reset('location'))
          dispatch(reset('appointmentSlot'))

          // then we want to go straight to the Location
          setFlow('/App/Form/Data/ProvideData/Location')
        }

        dispatch(set('dentalPlan', value))
      }}
      value={data.dentalPlan}
    />
  )
}
