import { DATA, set } from './helpers.js'
import { useEffect, useMemo, useState } from 'react'
import { useFlow, useSetFlowTo } from 'Simple/Flow.js'
import { useData, useDataDispatch } from './useData.js'
import useDebounce from 'Logic/use-debounce.js'
import toCamelCase from 'to-camel-case'
import useSetFlowToNextStory from './useSetFlowToNextStory.js'
import { toCapitalCase } from 'Logic/toCapitalCase.js'

export default function useField(fieldId) {
  let data = useData()
  let dispatch = useDataDispatch()
  let camelCaseField = useMemo(() => toCamelCase(fieldId), [fieldId])
  let field = DATA[camelCaseField]

  let [value, onChange] = useState(data[camelCaseField])

  // valid
  let flow = useFlow()
  let setFlow = useSetFlowTo()
  let valueStable = useDebounce(value, 200)
  let isValid = useMemo(() => field.isValid(valueStable), [valueStable, field])

  useEffect(() => {
    if (!flow.has(`/App/Form/Data/ProvideData/${fieldId}`)) return

    let status = 'Valid'

    if (valueStable === '') {
      status = 'Empty'
    } else if (!isValid) {
      status = 'Invalid'
    }

    setFlow(`/App/Form/Data/ProvideData/${fieldId}/Status/${status}`)
  }, [fieldId, isValid]) // eslint-disable-line
  // setFlow doesn't change

  // next question
  let setFlowToNextStory = useSetFlowToNextStory(fieldId)
  function onSubmit() {
    if (!flow.has(`/App/Form/Data/ProvideData/${fieldId}`)) return
    if (!isValid) return

    let transformedValue = field.shouldBeCapitalized
      ? toCapitalCase(value)
      : value

    dispatch(set(field.id, transformedValue))
    setFlowToNextStory()
    return true
  }

  return { value, onChange, onSubmit }
}
