import { useEffect, useState, useRef } from 'react'
import loadScript from 'Logic/loadScript.js'

window._googleMapsCallback = () => {}

export default function useGoogleMapsApi({ key, libraries = '' }) {
  let api = useRef(
    window.google && window.google.maps ? window.google.maps : null
  )
  let [state, setState] = useState(api.current ? 'ready' : 'loading')

  useEffect(() => {
    if (api.current) return
    async function run() {
      try {
        await loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=${libraries}&callback=_googleMapsCallback`
        )
        api.current = window.google.maps
        setState('ready')
      } catch (error) {
        setState('error')
      }
    }
    run()
  }, []) // eslint-disable-line

  return {
    api: api.current,
    isError: state === 'error',
    isLoading: state === 'loading',
    isReady: state === 'ready',
  }
}
