import {
  getClosestZipcodeForLocation,
  useSortedLocations,
} from 'Logic/useSortedLocations.js'
import { set, reset } from 'Data/helpers.js'
import { useData, useDataDispatch } from 'Data/useData.js'
import { useLocations } from 'Data/Locations.js'
import { useSetFlowTo } from 'Simple/Flow.js'
import { useUserLocation } from 'DesignSystem/Map/userLocation.js'
import View from './view.js'
import React, { useEffect, useRef, useState } from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'
import useDebounce from 'Logic/use-debounce.js'
import useSetFlowToNextStory from 'Data/useSetFlowToNextStory.js'
import useFocusArea from 'Logic/useFocusArea.js'
import useInputKey from 'Logic/useInputKey.js'
import useRecaptcha from 'Logic/use-recaptcha.js'
import qs from 'querystringify'

let DEFAULT_ZIP = qs.parse(window.location.search).zip || ''

export default function Logic(props) {
  let data = useData()
  let recaptcha = useRecaptcha()

  let locations = useLocations(data.dentalPlan)
  let dispatch = useDataDispatch()
  let [zipInput, setZipInput] = useState(DEFAULT_ZIP)
  let [center, setCenter] = useState()
  let [highlightedLocation, setHighlightedLocation] = useState(data.location)
  let [hoveredMarker, setHoveredMarker] = useState(data.location)

  let choices = useSortedLocations(locations, zipInput)
  let userLocation = useUserLocation()
  let setFlow = useSetFlowTo()
  let setFlowToNextStory = useSetFlowToNextStory('Location')
  let focusArea = useFocusArea()

  let listRefs = useRef({})
  let listElementRef = (id, el) => (listRefs.current[id] = el)

  let [inputKey, nextInputKey] = useInputKey()

  useEffect(() => {
    let cancel = false
    if (!userLocation || zipInput) return

    setCenter(userLocation)
    ;(async () => {
      let captcha_token = await recaptcha.getToken()

      if (!captcha_token) return

      let zipForLocation = await getClosestZipcodeForLocation({
        coords: userLocation,
        captcha_token,
      })

      if (cancel || !zipForLocation) return

      setZipInput(zipForLocation)
      nextInputKey()
    })()

    return () => (cancel = true)
  }, [userLocation, zipInput, recaptcha]) //eslint-disable-line
  // nextInputKey doesn't change

  let hoveredChoiceStable = useDebounce(hoveredMarker)
  useEffect(() => {
    if (hoveredChoiceStable && choices.length > 0) {
      let selectedMarker = choices.find(({ id }) => id === hoveredChoiceStable)
      if (selectedMarker) {
        setCenter({ lat: selectedMarker.lat, lng: selectedMarker.lng })
      }
    }
  }, [hoveredChoiceStable, choices])

  function ensureHighlightedLocationCardIsVisible() {
    let card = listRefs.current[highlightedLocation]
    if (card) {
      scrollIntoView(card, { behavior: 'smooth', scrollMode: 'if-needed' })
    }
  }
  useEffect(ensureHighlightedLocationCardIsVisible, [highlightedLocation])

  // when the user stopped hovering over cards, we want to ensure that the
  // highlighted location is visible on the list again
  let hoveredMarkerStable = useDebounce(hoveredMarker, 1000)
  useEffect(() => {
    if (!hoveredMarkerStable) {
      ensureHighlightedLocationCardIsVisible()
    }
  }, [hoveredMarkerStable]) // eslint-disable-line
  // ensureHighlightedLocationCardIsVisible doesn't count

  useEffect(() => {
    if (choices === locations) return

    let firstChoice = choices && choices[0]
    let card = listRefs.current[firstChoice && firstChoice.id]
    if (card) {
      scrollIntoView(card, { behavior: 'smooth', scrollMode: 'if-needed' })
    }
  }, [choices]) // eslint-disable-line
  // we don't care about locations here

  // the highlighted location should be unset if a marker is hovered
  let maybeHighlightedLocation = hoveredMarker ? null : highlightedLocation

  return (
    <View
      {...props}
      center={center}
      choices={choices}
      dentalPlan={data.dentalPlan}
      focusArea={focusArea}
      highlightedLocation={maybeHighlightedLocation}
      hoveredMarker={hoveredMarker}
      inputKey={inputKey}
      listElementRef={listElementRef}
      markers={locations}
      onChange={setZipInput}
      onMouseEnter={setHoveredMarker}
      onMouseLeave={() => setHoveredMarker(null)}
      onSelect={(value, locationVisitType) => {
        if (value === data.location) {
          setFlowToNextStory()
        } else {
          // the location choice influences the appointment slot, so we
          // need to reset it when the value changes
          dispatch(reset('appointmentSlot'))

          // then we want to go straight to the Location
          setFlow('/App/Form/Data/ProvideData/AppointmentSlot')
        }

        dispatch(set('locationVisitType', locationVisitType))
        dispatch(set('location', value))
      }}
      selected={data.location}
      selectedLocationVisitType={data.locationVisitType}
      setHighlightedLocation={setHighlightedLocation}
      userLocation={userLocation}
      zipInput={zipInput}
    />
  )
}
