import {
  CAPTURE,
  CHOICE,
  INVALID_FIELD_MESSAGE,
  MULTICHOICE,
  PHONE_TYPES,
  QUESTION_SKIPPED,
  SET,
  TOGGLE,
} from './common-constants.js'
import {
  isValidAppointment,
  isValidDentalPlan,
  isValidEmail,
  isValidLocation,
  isValidName,
  isValidPhone,
  isValidPhoneType,
  isValidRelationship,
  isValidResponsiblePerson,
  isValidTrueFalse,
  isValidBirthday,
  isValidLocationVisitType,
} from './validators.js'
import toPascalCase from 'to-pascal-case'
import toSentenceCase from 'to-sentence-case'
import { toCapitalCase } from 'Logic/toCapitalCase.js'

// TODO I'd move DATA into Data/definitions.js
export let DATA = {
  dentalPlan: {
    defaultValue: null,
    type: CHOICE,
    id: 'dentalPlan',
    isValid: isValidDentalPlan,
    format: ({ dentalPlan }) =>
      dentalPlan ? toSentenceCase(dentalPlan) : null,
  },
  location: {
    defaultValue: null,
    type: CHOICE,
    id: 'location',
    isValid: isValidLocation,
  },
  locationVisitType: {
    defaultValue: 'inPerson',
    type: CHOICE,
    id: 'locationVisitType',
    isValid: isValidLocationVisitType,
    isSecondary: true,
  },
  appointmentSlot: {
    defaultValue: {},
    type: CAPTURE,
    id: 'appointmentSlot',
    isValid: isValidAppointment,
    format: ({ appointmentSlot }) =>
      appointmentSlot.date && appointmentSlot.time
        ? `${appointmentSlot.date} ${appointmentSlot.time}`
        : null,
  },
  patientFirstName: {
    defaultValue: '',
    type: CAPTURE,
    id: 'patientFirstName',
    isValid: isValidName,
    shouldBeCapitalized: true,
    format: ({ patientFirstName }) => {
      return patientFirstName ? toCapitalCase(patientFirstName) : ''
    },
  },
  patientLastName: {
    defaultValue: '',
    type: CAPTURE,
    id: 'patientLastName',
    isValid: isValidName,
    shouldBeCapitalized: true,
    format: ({ patientLastName }) => {
      return patientLastName ? toCapitalCase(patientLastName) : ''
    },
  },
  patientEmail: {
    defaultValue: '',
    type: CAPTURE,
    id: 'patientEmail',
    isValid: isValidEmail,
  },
  patientPhone: {
    defaultValue: '',
    type: CAPTURE,
    id: 'patientPhone',
    isValid: isValidPhone,
  },
  patientPhoneType: {
    defaultValue: PHONE_TYPES[0],
    type: CHOICE,
    id: 'patientPhoneType',
    isValid: isValidPhoneType,
    isSecondary: true,
  },
  patientPhoneNotifications: {
    defaultValue: true,
    id: 'patientPhoneNotifications',
    isValid: isValidTrueFalse,
    type: TOGGLE,
    isSecondary: true,
  },
  patientPhoneConsent: {
    defaultValue: true,
    id: 'patientPhoneConsent',
    isValid: isValidTrueFalse,
    type: TOGGLE,
    isSecondary: true,
  },
  patientBirthday: {
    defaultValue: '',
    mockValue: '08/26/1983',
    type: CAPTURE,
    id: 'patientBirthday',
    isValid: isValidBirthday,
  },
  responsiblePerson: {
    defaultValue: null,
    type: CHOICE,
    id: 'responsiblePerson',
    isValid: isValidResponsiblePerson,
    format: ({
      patientFirstName,
      patientLastName,
      responsiblePerson,
      responsiblePersonFirstName,
      responsiblePersonLastName,
    }) =>
      responsiblePerson === 'other-person'
        ? responsiblePersonFirstName && responsiblePersonLastName
          ? `${toCapitalCase(responsiblePersonFirstName)} ${toCapitalCase(
              responsiblePersonLastName
            )}`
          : 'Other Person'
        : responsiblePerson === 'patient'
        ? `${toCapitalCase(patientFirstName)} ${toCapitalCase(patientLastName)}`
        : null,
  },
  responsiblePersonFirstName: {
    defaultValue: '',
    type: CAPTURE,
    id: 'responsiblePersonFirstName',
    isValid: isValidName,
    onWhen: data =>
      data.responsiblePerson === 'other-person' || !data.responsiblePerson,
    shouldBeCapitalized: true,
    format: ({ responsiblePersonFirstName }) => {
      return responsiblePersonFirstName
        ? toCapitalCase(responsiblePersonFirstName)
        : ''
    },
  },
  responsiblePersonLastName: {
    defaultValue: '',
    type: CAPTURE,
    id: 'responsiblePersonLastName',
    isValid: isValidName,
    onWhen: data =>
      data.responsiblePerson === 'other-person' || !data.responsiblePerson,
    shouldBeCapitalized: true,
    format: ({ responsiblePersonLastName }) => {
      return responsiblePersonLastName
        ? toCapitalCase(responsiblePersonLastName)
        : ''
    },
  },
  responsiblePersonEmail: {
    defaultValue: '',
    type: CAPTURE,
    id: 'responsiblePersonEmail',
    isValid: isValidEmail,
    onWhen: data =>
      data.responsiblePerson === 'other-person' || !data.responsiblePerson,
  },
  responsiblePersonUsePatientEmail: {
    defaultValue: false,
    id: 'responsiblePersonUsePatientEmail',
    isValid: isValidTrueFalse,
    type: TOGGLE,
    onWhen: data =>
      data.responsiblePerson === 'other-person' || !data.responsiblePerson,
    isSecondary: true,
  },
  responsiblePersonPhone: {
    defaultValue: '',
    type: CAPTURE,
    id: 'responsiblePersonPhone',
    isValid: isValidPhone,
    onWhen: data =>
      data.responsiblePerson === 'other-person' || !data.responsiblePerson,
  },
  responsiblePersonPhoneType: {
    defaultValue: PHONE_TYPES[0],
    type: CHOICE,
    id: 'responsiblePersonPhoneType',
    isValid: isValidPhoneType,
    onWhen: data =>
      data.responsiblePerson === 'other-person' || !data.responsiblePerson,
    isSecondary: true,
  },
  responsiblePersonUsePatientPhone: {
    defaultValue: false,
    id: 'responsiblePersonUsePatientPhone',
    isValid: isValidTrueFalse,
    type: TOGGLE,
    onWhen: data =>
      data.responsiblePerson === 'other-person' || !data.responsiblePerson,
    isSecondary: true,
  },
  responsiblePersonPhoneNotifications: {
    defaultValue: true,
    id: 'responsiblePersonPhoneNotifications',
    isValid: isValidTrueFalse,
    type: TOGGLE,
    onWhen: data =>
      data.responsiblePerson === 'other-person' || !data.responsiblePerson,
    isSecondary: true,
  },
  responsiblePersonPhoneConsent: {
    defaultValue: true,
    id: 'responsiblePersonPhoneConsent',
    isValid: isValidTrueFalse,
    type: TOGGLE,
    onWhen: data =>
      data.responsiblePerson === 'other-person' || !data.responsiblePerson,
    isSecondary: true,
  },
  responsiblePersonRelationship: {
    defaultValue: null,
    type: CHOICE,
    id: 'responsiblePersonRelationship',
    isValid: isValidRelationship,
    onWhen: data =>
      data.responsiblePerson === 'other-person' || !data.responsiblePerson,
  },
}

export let DATA_KEYS = Object.keys(DATA)

export let getRepeaters = (data, nextInvalidField, exclude) => {
  let repeaters = {}
  DATA_KEYS.filter(key => !exclude.includes(key)).forEach(key => {
    let keyRepeater = `${key}Repeater`
    let field = DATA[key]
    if (toPascalCase(key) === nextInvalidField) {
      repeaters[keyRepeater] = INVALID_FIELD_MESSAGE
    } else if (typeof field.format === 'function') {
      repeaters[keyRepeater] = field.format(data)
    } else {
      repeaters[keyRepeater] = data[key]
    }
  })
  return repeaters
}

export let getProgress = (valid, applicableData) => {
  let answeredFields = applicableData.filter(field => valid[`${field}Valid`])
  return answeredFields.length
    ? Math.round((answeredFields.length / applicableData.length) * 100)
    : 1
}

// The next step is a step that isn't valid yet
// if everything is valid, it means there's no next step.
// We return null to indicate that.
//
// Notice that we look at the Valid key and not the ValidTemp.
// We only care about final values.
//
// Since the states here are the QUESTION
export let getNextInvalidField = (valid, applicable, skip = null) => {
  // questions are filtered in the data.js file so that any question that is no longer applicable
  // will be taken out of the list of applicable questions & not shown to the user
  let next = applicable.find(key => key !== skip && !valid[`${key}Valid`])
  return next ? toPascalCase(next) : null
}

export let getApplicableData = (data, exclude) => {
  return DATA_KEYS.filter(key => {
    if (exclude.includes(key)) return false

    let field = DATA[key]
    if (field.isSecondary) return false

    return typeof field.onWhen === 'function' ? field.onWhen(data) : true
  })
}

export let set = (key, value) => ({
  type: SET,
  key,
  value,
})

export let reset = key => set(key, DATA[key].defaultValue)

export let skip = key => {
  let field = DATA[key]

  if (!field.canSkip) return false

  return set(
    key,
    field.type === MULTICHOICE ? { [QUESTION_SKIPPED]: true } : QUESTION_SKIPPED
  )
}
