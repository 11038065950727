import { set } from 'Data/helpers.js';
import { useData, useDataDispatch } from 'Data/useData.js';
import React from 'react';
import View from './view.js';
import useField from 'Data/useField.js';

export default function Logic(props) {
  let data = useData();
  let dispatch = useDataDispatch();
  let field = useField('PatientEmail');

  return (
    <View
      {...field}
      onSubmit={() => {
        if (field.onSubmit() && data.responsiblePersonUsePatientEmail) {
          dispatch(set('responsiblePersonEmail', field.value));
        }
      }}
    />
  );
}
