export let CAPTURE = 'CAPTURE'
export let CHOICE = 'CHOICE'
export let MULTICHOICE = 'MULTICHOICE'
export let GROUP = 'GROUP'
export let TOGGLE = 'TOGGLE'
export let LIST = 'LIST'

export let GROUP_MAYBE_NEXT_FROM = 'GROUP_MAYBE_NEXT_FROM'
export let GROUP_SET_TEMP = 'GROUP_SET_TEMP'
export let MAYBE_NEXT_FROM = 'MAYBE_NEXT_FROM'
export let MULTICHOICE_TOGGLE_TEMP = 'MULTICHOICE_TOGGLE_TEMP'
export let LIST_ADD = 'LIST_ADD'
export let SET = 'SET'
export let SET_TEMP = 'SET_TEMP'
export let SKIP = 'SKIP'
export let INITIAL_DATA = 'INITIAL_DATA'

export let PHONE_TYPES = ['CELL', 'LANDLINE']

export let QUESTION_SKIPPED = '___Question_Skipped___'

export let RELATIONSHIPS = [
  'Mother',
  'Father',
  'Sister',
  'Brother',
  'Grandmother',
  'Grandfather',
  'Partner',
  'Spouse',
  'Stepmother',
  'Stepfather',
  'Guardian',
  'Aunt',
  'Uncle',
  'Child',
  'Nephew',
  'Niece',
  'Grandson',
  'Granddaughter',
  'Stepchild',
  'Ward',
  'Other',
  QUESTION_SKIPPED,
]

export let INVALID_FIELD_MESSAGE = 'Please answer this question'

// TODO what Ryan did here with gender https://bitbucket.org/vaxiom/medical-form/pull-requests/75/chore-replace-gender-neutral/diff#Lsrc/Data/constants.jsT136
export let PRIVATE_INSURANCE = 'PRIVATE_INSURANCE'
export let MEDICAID = 'MEDICAID'
export let DENTAL_PLAN = {
  [PRIVATE_INSURANCE]: 'Private Insurance/No Insurance',
  [MEDICAID]: 'Medicaid',
}

export let LOCATION_VISIT_TYPE = ['inPerson', 'virtual']
