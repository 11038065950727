import View from './view.js'
import React from 'react'

export default function Logic(props) {
  let id = props.item ? props.item.id : props.id
  let subtext = props.item ? props.item.subtext : props.subtext

  return (
    <View
      {...props}
      {...props.item}
      isSelected={props.isSelected || (id && id === props.selected)}
      onClick={() => props.onClick(id)}
      hasSubtext={subtext}
      subtext={subtext}
    />
  )
}
