import { set } from 'Data/helpers.js'
import { useData, useDataDispatch } from 'Data/useData.js'
import React, { useEffect } from 'react'
import View from './view.js'
import useField from 'Data/useField.js'
import useInputKey from 'Logic/useInputKey.js'
import useSecondaryField from 'Data/useSecondaryField.js'

export default function Logic(props) {
  let data = useData()
  let dispatch = useDataDispatch()
  let field = useField('ResponsiblePersonPhone')
  let responsiblePersonPhoneType = useSecondaryField(
    'ResponsiblePersonPhoneType'
  )
  let responsiblePersonPhoneNotifications = useSecondaryField(
    'ResponsiblePersonPhoneNotifications'
  )
  let responsiblePersonPhoneConsent = useSecondaryField(
    'ResponsiblePersonPhoneConsent'
  )
  let responsiblePersonUsePatientPhone = useSecondaryField(
    'ResponsiblePersonUsePatientPhone'
  )

  let [inputKey, nextInputKey] = useInputKey()

  useEffect(() => {
    let equalsPhone = field.value === data.patientPhone

    if (responsiblePersonUsePatientPhone.value && !equalsPhone) {
      responsiblePersonUsePatientPhone.onChange(value => !value)
    } else if (!responsiblePersonUsePatientPhone.value && equalsPhone) {
      responsiblePersonUsePatientPhone.onChange(value => !value)
      responsiblePersonPhoneType.onChange(data.patientPhoneType)
    }
  }, [field.value]) // eslint-disable-line
  // data.patientPhoneType, data.patientPhone, responsiblePersonUsePatientPhone.onChange,
  // responsiblePersonPhoneType.onChange won't change
  // we don't want to react to responsiblePersonUsePatientPhone.value changing

  useEffect(() => {
    if (
      responsiblePersonUsePatientPhone.value &&
      responsiblePersonPhoneType.value !== data.patientPhoneType
    ) {
      dispatch(set('patientPhoneType', responsiblePersonPhoneType.value))
    }
  }, [responsiblePersonPhoneType.value]) // eslint-disable-line
  // we don't care about responsiblePersonUsePatientPhone.value,
  // data.patientPhoneType or dispatch

  return (
    <View
      {...field}
      inputKey={inputKey}
      onClickType={responsiblePersonPhoneType.onChange}
      onClickNotifications={() => {
        responsiblePersonPhoneNotifications.onChange(value => !value)
        if (!responsiblePersonPhoneConsent.value) {
          responsiblePersonPhoneConsent.onChange(true)
        }
      }}
      onClickConsentNotification={() => {
        if (responsiblePersonPhoneConsent.value) {
          responsiblePersonPhoneNotifications.onChange(false)
        }
        responsiblePersonPhoneConsent.onChange(value => !value)
      }}
      onClickUsePatientPhone={() => {
        let nextValue = ''
        if (!responsiblePersonUsePatientPhone.value) {
          nextValue = data.patientPhone
          responsiblePersonPhoneType.onChange(data.patientPhoneType)
        }

        nextInputKey()
        field.onChange(nextValue)
        responsiblePersonUsePatientPhone.onChange(value => !value)
      }}
      onSubmit={() => {
        if (field.onSubmit()) {
          responsiblePersonPhoneType.onSubmit()
          responsiblePersonPhoneNotifications.onSubmit()
          responsiblePersonUsePatientPhone.onSubmit()

          if (
            responsiblePersonUsePatientPhone.value &&
            responsiblePersonPhoneType.value !== data.patientPhoneType
          ) {
            dispatch(set('patientPhoneType', responsiblePersonPhoneType.value))
          }
        }
      }}
      valueType={responsiblePersonPhoneType.value}
      valueNotifications={responsiblePersonPhoneNotifications.value}
      valueUsePatientPhone={responsiblePersonUsePatientPhone.value}
      valueConsent={responsiblePersonPhoneConsent.value}
    />
  )
}
