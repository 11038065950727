import { Configuration } from 'Data/Configuration.js';
import { Locations } from 'Data/Locations.js';
import { Data } from 'Data/useData.js';
import { useSetFlowTo } from 'Simple/Flow.js';
import { useOnError } from 'Logic/ErrorBoundary.js';
import View from './view.js';
import makeElementResizeDetector from 'element-resize-detector';
import React, { useEffect, useRef } from 'react';
import getDivisionId from 'Data/useDivisionId.js';
import getBackgroundColor from 'Data/getBackgroundColor.js';

let gtmData = { divisionId: getDivisionId() };

export default function Logic(props) {
  let setFlow = useSetFlowTo(props.viewPath);
  let container = useRef();

  useOnError(() => setFlow('/App/RuntimeError'));
  useEffect(() => {
    let erd = makeElementResizeDetector({ strategy: 'scroll' });
    erd.listenTo(container.current, (element) => {
      window.parent.postMessage(
        JSON.stringify({
          greyfinch: true,
          type: 'greyfinch/appointments/resize',
          payload: {
            width: element.offsetWidth,
            height: element.offsetHeight,
          },
        }),
        '*'
      );
    });

    // Example on how to consume the resize event from an iframe
    //
    // <script>
    // // You could also add an id to the iframe like "greyfinch-appointment-widget-iframe" and
    // // use document.getElementById('greyfinch-appointment-widget-iframe') instead
    // var greyfinchAppointmentWidgetIframe = document.querySelector('iframe[src*="https://appointments.greyfinch.com"]');
    // window.addEventListener('message', function(event) {
    //   try {
    //     var data = JSON.parse(event.data);
    //     if (data.greyfinch && data.type === 'greyfinch/appointments/resize') {
    //       greyfinchAppointmentWidgetIframe.style.height = data.payload.height;
    //     }
    //   } catch (error) {}
    // });
    // </script>

    return () => erd.uninstall();
  }, []);

  return (
    <Configuration>
      <Locations>
        <Data>
          <View
            container={container}
            isShrunk={/shrink=true/.test(window.location.search)}
            backgroundColor={getBackgroundColor()}
            gtmData={gtmData}
            viewPath="/App"
          />
        </Data>
      </Locations>
    </Configuration>
  );
}