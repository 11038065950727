import { useEffect, useState, useRef } from 'react';
import loadScript from './loadScript.js';
import './use-recaptcha-api.css';

export default function useRecaptchaApi({ key }) {
  let api = useRef(window.grecaptcha || null);
  let [state, setState] = useState(api.current ? 'ready' : 'loading');

  useEffect(() => {
    if (api.current) return;

    async function run() {
      try {
        await loadScript(
          `https://www.google.com/recaptcha/api.js?render=${key}`
        );
        api.current = window.grecaptcha;
        setState('ready');
      } catch (error) {
        setState('error');
      }
    }
    run();
  }, []); // eslint-disable-line

  return {
    api: api.current,
    isError: state === 'error',
    isLoading: state === 'loading',
    isReady: state === 'ready',
  };
}
