import { INVALID_FIELD_MESSAGE } from 'Data/common-constants.js'
import { useData, useDataHelpers } from 'Data/useData.js'
import { useLocations } from 'Data/Locations.js'
import View from './view.js'
import React from 'react'

export default function Logic(props) {
  let data = useData()
  let locations = useLocations(data.dentalPlan)
  let dataHelpers = useDataHelpers()

  let noLocationChosen =
    dataHelpers.repeaters.locationRepeater === INVALID_FIELD_MESSAGE

  let text = noLocationChosen
    ? dataHelpers.repeaters.locationRepeater
    : `${
        locations.find(locationObject => locationObject.id === data.location)
          .text
      }${data.locationVisitType === 'virtual' ? ' • Virtual Visit' : ''}`

  return <View {...props} text={text} />
}
