import { set } from 'Data/helpers.js'
import { useData, useDataDispatch } from 'Data/useData.js'
import React from 'react'
import View from './view.js'
import useField from 'Data/useField.js'
import useSecondaryField from 'Data/useSecondaryField.js'

export default function Logic(props) {
  let data = useData()
  let dispatch = useDataDispatch()

  let field = useField('PatientPhone')
  let patientPhoneType = useSecondaryField('PatientPhoneType')
  let patientPhoneNotifications = useSecondaryField('PatientPhoneNotifications')
  let patientPhoneConsent = useSecondaryField('PatientPhoneConsent')

  return (
    <View
      {...field}
      onClickType={patientPhoneType.onChange}
      onClickNotifications={() => {
        patientPhoneNotifications.onChange(value => !value)

        if (!patientPhoneNotifications.value) {
          patientPhoneConsent.onChange(true)
        }
      }}
      onClickConsentNotification={() => {
        if (patientPhoneConsent.value) {
          patientPhoneNotifications.onChange(false)
        }
        patientPhoneConsent.onChange(value => !value)
      }}
      onSubmit={() => {
        if (field.onSubmit()) {
          patientPhoneType.onSubmit()
          patientPhoneNotifications.onSubmit()

          if (data.responsiblePersonUsePatientPhone) {
            dispatch(set('responsiblePersonPhone', field.value))
            dispatch(set('responsiblePersonPhoneType', patientPhoneType.value))
          }
        }
      }}
      valueType={patientPhoneType.value}
      valueNotifications={patientPhoneNotifications.value}
      valueConsent={patientPhoneConsent.value}
    />
  )
}
