import { useDataHelpers } from 'Data/useData.js'
import View from './view.js'
import React from 'react'
import usePreviousQuestion from 'Logic/use-previous-question.js'

export default function Logic(props) {
  let dataHelpers = useDataHelpers()
  let previousQuestion = usePreviousQuestion()

  return dataHelpers.isFieldExcluded('dentalPlan') ? null : (
    <View {...props} {...previousQuestion} />
  )
}
