import { useData } from 'Data/useData.js';
import { useLocations } from 'Data/Locations.js';
import { useSetFlowTo } from 'Simple/Flow.js';
import View from './view.js';
import React, { useEffect } from 'react';

export default function Logic(props) {
  let data = useData();
  let locations = useLocations(data.dentalPlan);
  let setFlow = useSetFlowTo();

  useEffect(() => {
    let story = locations.length > 0 ? 'Locations' : 'NoAppointmentAvailable';

    setFlow(`/App/Form/Data/ProvideData/Location/Content/${story}`);
  }, [locations.length, setFlow]);

  return <View {...props} />;
}
