import React from 'react';
import View from './view.js';
import { useConfiguration } from 'Data/Configuration.js';
import { useSetFlowTo } from 'Simple/Flow.js';

export default function Logic(props) {
  let setFlow = useSetFlowTo();
  let configuration = useConfiguration();
  return (
    <View
      {...props}
      onClick={() => {
        setFlow(
          `/App/Form/Data/ProvideData/${
            configuration.data.medicaidEnabled ? 'DentalPlan' : 'Location'
          }`
        );
      }}
    />
  );
}
