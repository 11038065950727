import { useSpring, animated } from '@react-spring/web'
import { getNextInvalidField } from 'Data/helpers.js'
import { useDataHelpers } from 'Data/useData.js'
import { useFlow, useSetFlowTo } from 'Simple/Flow.js'
import View from './view.js'
import React, { useEffect } from 'react'

let AnimatedOngoing = animated(View)

export default function Logic(props) {
  let dataHelpers = useDataHelpers()
  let setFlow = useSetFlowTo()
  let flowState = useFlow()
  let isReviewData = flowState.has('/App/Form/Data/ReviewData')

  // animate the width with a spring because Tom wanted to
  let [progressAnimated, progressAnimatedSet] = useSpring(() => ({
    to: {
      width: `0%`,
    },
    config: {
      tension: 220,
      friction: 8,
    },
  }))

  useEffect(() => {
    progressAnimatedSet({ width: `${dataHelpers.progress}%` })

    if (dataHelpers.progress === 100 && isReviewData) {
      setFlow('/App/Form/Progress/ReadyToSubmit')
    }

    if (dataHelpers.progress !== 100) {
      setFlow('/App/Form/Progress/Ongoing')
    }
  }, [dataHelpers.progress, setFlow, isReviewData]) // eslint-disable-line

  return (
    <AnimatedOngoing
      {...props}
      isReviewData={isReviewData}
      toggleProvideDataReviewData={() => {
        let story = '/App/Form/Data/ReviewData'

        if (isReviewData) {
          let next = getNextInvalidField(
            dataHelpers.valid,
            dataHelpers.applicableData
          )

          story = next
            ? `/App/Form/Data/ProvideData/${next}`
            : '/App/Form/Data/ProvideData'
        }

        setFlow(story)
      }}
      progress={progressAnimated.width}
    />
  )
}
