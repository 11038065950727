import { useData, useDataDispatch } from 'Data/useData.js';
import { set } from 'Data/helpers.js';
import React from 'react';
import View from './view.js';
import useField from 'Data/useField.js';

export default function Logic(props) {
  let field = useField('ResponsiblePersonEmail');
  let data = useData();
  let dispatch = useDataDispatch();

  function toggleUsePatientEmail() {
    dispatch(
      set(
        'responsiblePersonUsePatientEmail',
        !data.responsiblePersonUsePatientEmail
      )
    );
  }

  return (
    <View
      {...field}
      onChange={value => {
        field.onChange(value);

        let { responsiblePersonUsePatientEmail, patientEmail } = data;

        let equalsEmail = value === patientEmail;

        if (
          (responsiblePersonUsePatientEmail && !equalsEmail) ||
          (!responsiblePersonUsePatientEmail && equalsEmail)
        ) {
          toggleUsePatientEmail();
        }
      }}
      onClickUsePatientEmail={() => {
        field.onChange(
          data.responsiblePersonUsePatientEmail ? '' : data.patientEmail
        );

        toggleUsePatientEmail();
      }}
      usePatientEmail={data.responsiblePersonUsePatientEmail}
    />
  );
}
